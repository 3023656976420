import React from "react";
import Error from "../Components/Sections/Error";

const ErrorPage = () => {
    return (
        <main id="main">
            <Error/>
        </main>
    );
}

export default ErrorPage;